// Components
import { UnsubscribeEmail } from "components/UnsubscribeEmail"
import { BackToLinksSubHeader } from "components/BackToLinksSubHeader"

export function UnsubscribeEmailPage() {
  return (
    <>
      <BackToLinksSubHeader />
      <UnsubscribeEmail />
    </>
  )
}
