import { useEffect, useState, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { ApiUtils } from "Utils/ApiUtils"

// Contexts
import { SnackBarContext } from "contexts/SnackBarContext"

// MUI
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

// Types
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

export const UnsubscribeEmail = () => {
  const [isUnsubscribing, setIsUnsubscribing] = useState<Boolean>(false)
  const [isError, setIsError] = useState<Boolean>(false)
  const [isUnsubscribed, setIsUnsubscribed] = useState<Boolean>(false)

  const { setSnackBarState } = useContext(SnackBarContext)
  const navigate = useNavigate()

  let { code } = useParams()

  useEffect(() => {
    if (code) {
      setIsUnsubscribing(true)
      setIsError(false)

      ApiUtils.unsubscribeFromEmails(code).then(
        () => {
          setIsUnsubscribing(false)
          setIsError(false)
          setIsUnsubscribed(true)
        },
        (res: any) => {
          setIsUnsubscribing(false)
          setIsError(false)
          setIsUnsubscribed(true)

          // setSnackBarState({
          //   isOpen: true,
          //   snackBarType: SNACK_BAR_TYPES.ERROR,
          //   message: "Something went wrong.",
          // })
        }
      )
    } else {
      setIsUnsubscribing(false)
      setIsError(true)
      setSnackBarState({
        isOpen: true,
        snackBarType: SNACK_BAR_TYPES.ERROR,
        message: "Something went wrong.",
      })
    }
    return () => {}
  }, [code, setSnackBarState])

  const isUnsubscribingMessage = () => {
    if (isUnsubscribing) {
      return (
        <>
          <Typography sx={{ pt: 3 }} textAlign="center" variant="h4">
            Unsubscribing email, please wait...
          </Typography>
          <Box justifyContent="center" alignItems="center" sx={{ pt: 5, display: "flex" }}>
            <CircularProgress />
          </Box>
        </>
      )
    }
  }

  const isErrorMessage = () => {
    if (isError) {
      return (
        <>
          <Typography sx={{ pt: 3 }} textAlign="center" variant="h5">
            Something went wrong, please try again.
          </Typography>
        </>
      )
    }
  }

  const isUnsubscribedMessage = () => {
    if (isUnsubscribed) {
      return (
        <>
          <Typography sx={{ pt: 3 }} textAlign="center" variant="h5">
            You have been unsubscribed.
          </Typography>

          <Box justifyContent="center" alignItems="center" sx={{ pt: 5, display: "flex" }}>
            <Button
              onClick={() => navigate("/links")}
              variant="outlined"
              color="success"
              sx={{ mt: { xs: 0, sm: 1.5 }, ml: { xs: 2, sm: 0 } }}
            >
              Back to Linkidex
            </Button>
          </Box>
        </>
      )
    }
  }

  return (
    <>
      {isUnsubscribingMessage()}
      {isErrorMessage()}
      {isUnsubscribedMessage()}
    </>
  )
}
