import { CurrentUserInterface } from "../interfaces/User"

const handleResponse = (res: Response) => {
  if (res.ok) {
    return res.json()
  } else {
    return res.json().then((e) => Promise.reject(e))
  }
}
const headers = {
  "Content-Type": "application/json",
  credentials: "include",
}
const credentials = "include"

export const ApiUtils = {
  // Sessions
  getCurrentSession: (): Promise<CurrentUserInterface> =>
    fetch(`${process.env.PUBLIC_URL}/api/session/`, {
      method: "GET",
      headers,
      credentials,
    }).then(handleResponse),
  createSession: (body: { credential: string; password: string; rememberMe: boolean }): Promise<CurrentUserInterface> =>
    fetch(`${process.env.PUBLIC_URL}/api/session/`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        credential: body.credential,
        password: body.password,
        remember_me: body.rememberMe,
      }),
    }).then(handleResponse),
  secondFactor: (body: any): Promise<CurrentUserInterface> =>
    fetch(`${process.env.PUBLIC_URL}/api/session/create_second_factor/`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        login_stage_two_code: body.login_stage_two_code,
        remember_me: body.remember_me,
        otp: body.otp,
      }),
    }).then(handleResponse),
  loginWithRecoveryCode: (body: any): Promise<CurrentUserInterface> =>
    fetch(`${process.env.PUBLIC_URL}/api/session/login_with_recovery_code/`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        login_stage_two_code: body.login_stage_two_code,
        recovery_code: body.recovery_code,
      }),
    }).then(handleResponse),
  deleteSession: (): Promise<CurrentUserInterface> =>
    fetch(`${process.env.PUBLIC_URL}/api/session/`, {
      method: "DELETE",
      headers,
      credentials,
    }).then(handleResponse),

  // Users
  createUser: (body: { email: string; password: string; rememberMe: boolean }): Promise<CurrentUserInterface> =>
    fetch(`${process.env.PUBLIC_URL}/api/users/`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        email: body.email,
        password: body.password,
        remember_me: body.rememberMe,
      }),
    }).then(handleResponse),
  sendAccountRecoveryEmail: (body: { email: string }) =>
    fetch(`${process.env.PUBLIC_URL}/api/users/send_account_recovery_email`, {
      method: "PUT",
      headers,
      credentials,
      body: JSON.stringify({
        email: body.email,
      }),
    }).then(handleResponse),
  recoverAccount: (body: { newPassword: string; code: string | undefined }) =>
    fetch(`${process.env.PUBLIC_URL}/api/users/recover_account`, {
      method: "PUT",
      headers,
      credentials,
      body: JSON.stringify({
        newPassword: body.newPassword,
        code: body.code,
      }),
    }).then(handleResponse),
  getTwoFactorDetails: (body: { password: string }) =>
    fetch(`${process.env.PUBLIC_URL}/api/users/get_two_factor_details`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        password: body.password,
      }),
    }).then(handleResponse),
  registerTwoFactorAuthenticator: (body: { code: string; login_stage_two_code: string }) =>
    fetch(`${process.env.PUBLIC_URL}/api/users/register_two_factor`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        code: body.code,
        login_stage_two_code: body.login_stage_two_code,
      }),
    }).then(handleResponse),
  removeTwoFactorAuthenticator: (body: { login_stage_two_code: string }) =>
    fetch(`${process.env.PUBLIC_URL}/api/users/remove_two_factor`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        login_stage_two_code: body.login_stage_two_code,
      }),
    }).then(handleResponse),
  getBackupCodes: (body: { password: string }) =>
    fetch(`${process.env.PUBLIC_URL}/api/users/show_recovery_codes`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        password: body.password,
      }),
    }).then(handleResponse),
  resetBackupCodes: (body: { login_stage_two_code: string }) =>
    fetch(`${process.env.PUBLIC_URL}/api/users/reset_recovery_codes`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        login_stage_two_code: body.login_stage_two_code,
      }),
    }).then(handleResponse),
  recoverAccountWithTwoFactor: (body: { login_stage_two_code: string }) =>
    fetch(`${process.env.PUBLIC_URL}/api/users/recover_account_with_two_factor`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        login_stage_two_code: body.login_stage_two_code,
      }),
    }).then(handleResponse),
  getWebauthnDetails: (body: { password: string }) =>
    fetch(`${process.env.PUBLIC_URL}/api/webauthn/credentials/get_credentials`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        password: body.password,
      }),
    }).then(handleResponse),
  createWebauthnChallenge: (login_stage_two_code: string) =>
    fetch(`${process.env.PUBLIC_URL}/api/webauthn/challenges/create_challenge`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        login_stage_two_code: login_stage_two_code,
      }),
    }).then(handleResponse),
  createWebauthnCredential: (body: { credential: any; nickname: string; login_stage_two_code: string }) =>
    fetch(`${process.env.PUBLIC_URL}/api/webauthn/credentials/create_credential`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        credential: body.credential,
        nickname: body.nickname,
        login_stage_two_code: body.login_stage_two_code,
      }),
    }).then(handleResponse),
  authenticateWebauthnCredential: (body: { credential: any; login_stage_two_code: string; remember_me: boolean }) =>
    fetch(`${process.env.PUBLIC_URL}/api/webauthn/authentications/create`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        credential: body.credential,
        login_stage_two_code: body.login_stage_two_code,
        remember_me: body.remember_me,
      }),
    }).then(handleResponse),
  authenticateChallengeWebauthnCredential: (body: { login_stage_two_code: string }) =>
    fetch(`${process.env.PUBLIC_URL}/api/webauthn/authentications/login_challenge`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        login_stage_two_code: body.login_stage_two_code,
      }),
    }).then(handleResponse),
  deleteWebauthnDetail: (body: { id: string; login_stage_two_code: string }) =>
    fetch(`${process.env.PUBLIC_URL}/api/webauthn/credentials/destroy`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        id: body.id,
        login_stage_two_code: body.login_stage_two_code,
      }),
    }).then(handleResponse),

  // bookmarks
  getPresignedS3Url: (body: { fileName: string }) =>
    fetch(`${process.env.PUBLIC_URL}/api/aws_s3/presigned_url`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        file_name: body.fileName,
      }),
    }).then(handleResponse),
  uploadBookmarksSuccess: (fileUploadId: number) =>
    fetch(`${process.env.PUBLIC_URL}/api/users/upload_bookmarks_success`, {
      method: "PUT",
      headers,
      credentials,
      body: JSON.stringify({
        file_upload_id: fileUploadId,
      }),
    }).then(handleResponse),
  exportBookmarks: (userId: number) =>
    fetch(`${process.env.PUBLIC_URL}/api/users/export_bookmarks`, {
      method: "PUT",
      headers,
      credentials,
      body: JSON.stringify({
        user_id: userId,
      }),
    }).then(handleResponse),
  createCustomerPortalSession: (subscriberableType: string, subscriberableId: string | number) =>
    fetch(`${process.env.PUBLIC_URL}/api/stripe/create_portal_session`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        subscriberable_type: subscriberableType,
        subscriberable_id: subscriberableId,
      }),
    }).then(handleResponse),
  unsubscribeFromEmails: (code: string) =>
    fetch(`${process.env.PUBLIC_URL}/api/users/unsubscribe_email`, {
      method: "POST",
      headers,
      credentials,
      body: JSON.stringify({
        code: code,
      }),
    }).then(handleResponse),
}
