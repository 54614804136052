import React from "react"
import { useQuery } from "@apollo/client"

// MUI
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"

import { SxProps } from "@mui/material"

// Components
import { ResourceUsageRow } from "./ResourceUsageRow"
import { EmailDetailsRow } from "./EmailDetailsRow"
import { PasswordDetailsRow } from "./PasswordDetailsRow"
import { ImportBookmarksRow } from "./ImportBookmarksRow"
import { ExportBookmarksRow } from "./ExportBookmarksRow"
import { UseCategoriesAndTagsForAiSuggestionsRow } from "./UseCategoriesAndTagsForAiSuggestionsRow"
import { EmailUnsubscribeRow } from "./EmailUnsubscribeRow"
import { SecondFactorEnabledRow } from "./SecondFactorEnabledRow"
import { BackupCodeRow } from "./BackupCodeRow"
import { WebauthnRow } from "./WebauthnRow"

// Queries
import { getActiveUserWithResourcesQuery } from "queries/queries"

export const AccountDetails = () => {
  const textStyle = {
    ml: {
      xs: 2,
      md: 5,
    },
    wordBreak: "break-word",
  } as SxProps
  const { data } = useQuery(getActiveUserWithResourcesQuery, { fetchPolicy: "network-only" })

  return (
    <Box
      sx={{
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "1200px",
      }}
    >
      <Box>
        <Typography variant="h3" sx={{ ml: 5, my: 2 }}>
          Account
        </Typography>
        <Grid container spacing={2} sx={{ px: 2 }}>
          <EmailDetailsRow textStyle={textStyle} />
          <PasswordDetailsRow textStyle={textStyle} />
          <ImportBookmarksRow textStyle={textStyle} activeUser={data?.activeUser} />
          <ExportBookmarksRow textStyle={textStyle} />
          <EmailUnsubscribeRow textStyle={textStyle} />
          <UseCategoriesAndTagsForAiSuggestionsRow textStyle={textStyle} />
        </Grid>
        <Divider sx={{ my: 4 }} />
        <Typography variant="h3" sx={{ ml: 5, mt: 4, mb: 2 }}>
          Security
        </Typography>
        <Grid container spacing={2} sx={{ mb: 4, px: 2 }}>
          <SecondFactorEnabledRow textStyle={textStyle} />
          <BackupCodeRow textStyle={textStyle} />
          <WebauthnRow textStyle={textStyle} />
        </Grid>
        <Divider sx={{ my: 4 }} />

        <Grid container spacing={2} sx={{ px: 2 }}>
          <Typography variant="h3" sx={{ ml: 5, mt: 4, mb: 2 }}>
            Subscription & Usage
          </Typography>
          <Grid container spacing={2} sx={{ mb: 4, px: 2 }}>
            <ResourceUsageRow textStyle={textStyle} activeUser={data?.activeUser} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
