import { useContext, useState, useEffect, useRef } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { debounceFunction } from "Utils/Utils"

// MUI
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { SxProps } from "@mui/material"
import Switch from "@mui/material/Switch"

// Contexts
import { SnackBarContext } from "contexts/SnackBarContext"

// Types
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { DEBOUNCE_TIME } from "constants/Global"

// Queries
import {
  getUserSettingsQuery,
  createUserSettingMutation,
  updateUserSettingMutation,
  writeUserSettingQuery,
} from "queries/queries"

interface PasswordDetailsRowInterface {
  textStyle: SxProps
}

interface UserSettingInterface {
  id: string
  settingType: string
  settingValue: string
}

const EMAIL_SUBSCRIBED = "email_subscribed"

export function EmailUnsubscribeRow({ textStyle }: PasswordDetailsRowInterface) {
  const timeout = useRef<any>()

  const [updateUserSetting] = useMutation(updateUserSettingMutation)
  const { loading, data } = useQuery(getUserSettingsQuery)
  const { setSnackBarState } = useContext(SnackBarContext)
  const [userSetting, setUserSetting] = useState<UserSettingInterface>()

  const [createUserSetting] = useMutation(createUserSettingMutation, {
    update(cache, { data: { createUserSetting } }) {
      cache.modify({
        fields: {
          userSettings(existingUserSettings = []) {
            const newUserSettingRef = cache.writeFragment({
              data: createUserSetting,
              fragment: writeUserSettingQuery,
            })
            return [...existingUserSettings, newUserSettingRef]
          },
        },
      })
    },
  })

  const categoriesAndTagSetting = () => {
    const setting = data?.userSettings?.find(
      (userSetting: UserSettingInterface) => userSetting.settingType === EMAIL_SUBSCRIBED
    )
    return setting
  }

  useEffect(() => {
    if (loading) {
      return
    }
    const setting = categoriesAndTagSetting()
    if (setting) {
      setUserSetting(setting)
    } else {
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesAndTagSetting(), loading])

  const toggleUseCategoriesAndTags = () => {
    if (userSetting) {
      handleUpdateUserSetting()
    } else {
      handleCreateUserSetting()
    }
  }

  const debouncedToggleEmailSubscribed = () => {
    debounceFunction(timeout, () => toggleUseCategoriesAndTags(), DEBOUNCE_TIME)
  }

  const handleCreateUserSetting = () => {
    createUserSetting({
      variables: {
        settingValue: "false",
        settingType: EMAIL_SUBSCRIBED,
      },
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Settings updated",
        })
      },
      (res: any) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.ERROR,
          message: "Failed to update your user settings, please try again.",
        })
      }
    )
  }

  const handleUpdateUserSetting = () => {
    updateUserSetting({
      variables: {
        id: userSetting?.id,
        settingValue: userSetting?.settingValue === "true" ? "false" : "true",
        settingType: userSetting?.settingType,
      },
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Settings updated",
        })
      },
      (res: any) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.ERROR,
          message: "Failed to update your user settings, please try again.",
        })
      }
    )
  }

  const parseUserSettingValue = () => {
    // default value is true for email_subscribed
    if (userSetting?.settingValue === undefined) {
      return true
    }
    return userSetting?.settingValue === "true"
  }

  return (
    <>
      {/* Left part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={8}>
        <Typography sx={textStyle} variant="h6">
          Subscribe to Newsletter.
        </Typography>
        <Typography sx={textStyle} variant="subtitle2">
          This includes tips, requests for feedback, and (maybe) free stuff.
        </Typography>
      </Grid>

      {/* Right part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={4}>
        <Switch
          checked={parseUserSettingValue()}
          aria-label="use-categories-and-tags"
          onClick={debouncedToggleEmailSubscribed}
          sx={{ mt: { xs: 0, sm: 1.5 }, ml: { xs: 2, sm: 0 } }}
        />
      </Grid>
    </>
  )
}
